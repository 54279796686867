import React from 'react';
import { TwitterPicker } from 'react-color';

class BackgroundColorPicker extends React.Component {

  constructor(props) {
    super(props);
    this.handleHover = this.handleHover.bind(this);
    this.target = document.getElementById('finished');
    this.handleBackgroundChange = this.handleBackgroundChange.bind(this);
  }

  handleBackgroundChange(color) {
    this.props.onColorChange(color);
  }

  handleHover(color) {
    console.log(color);
  }
  render() {
    return <TwitterPicker 
              triangle="hide"
              width="100p"
              colors={['#9F0500', '#D33115', '#FE9200', '#FCDC00', '#808900', '#B0BC00', '#DBDF00', '#A4DD00',  '#0C797D', '#68CCCA', '#73D8FF', '#009CE0',  '#0062B1', '#653294', '#AB149E', '#7B64FF',  '#AEA1FF', '#FA28FF',  '#cccccc', '#808080', '#000000']} 
              onSwatchHover={this.handleHover}
              onChange={this.handleBackgroundChange}
            />
  }
}

export default BackgroundColorPicker;