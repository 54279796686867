import React from 'react';
import ForegroundColorPicker from "../components/ForegroundColorPicker";
import BackgroundColorPicker from "../components/BackgroundColorPicker";



class FinishedSentence extends React.Component {
  constructor(props) {
    super(props);
    this.handleForegroundColorChange = this.handleForegroundColorChange.bind(this);
    this.handleBackgroundColorChange = this.handleBackgroundColorChange.bind(this);
    this.state = {
        backgroundColor: '#e83e8c',
        foregroundClass: ''
    }
  }

  handleForegroundColorChange(props) {
    // Toggle
    let newClass = this.state.foregroundClass === 'finished-sentence--reverse' ? '' : 'finished-sentence--reverse';
    this.setState({foregroundClass: newClass});
  }

  handleBackgroundColorChange(color) {
    this.setState({backgroundColor: color.hex});
  }

  render() {
    return(
      <section  className={`finished-sentence ${this.state.foregroundClass}`}>
        <div className="color-picker mt-5 mb-3 row gx-3">
          <div className="col-auto">
            <ForegroundColorPicker onColorChange={this.handleForegroundColorChange} />
          </div>
          <div className="col col-lg-auto">
            <BackgroundColorPicker onColorChange={this.handleBackgroundColorChange} />
          </div>
        </div>

        <div id="finished-sentence__banner" className="finished-sentence__banner" style={{backgroundColor: this.state.backgroundColor}}>
          <div className="finished-sentence__text-helper">One time...</div>
          <span className="finished-sentence__text">I {this.props.sentenceVerb1} {this.props.sentenceObject2} and </span>
          <span className="finished-sentence__text">{this.props.sentenceVerb2} {this.props.sentenceObject1}</span>
          <div className="finished-sentence__text-helper">...and it worked!</div>
        </div>
    </section>
    );
  }
}

export default FinishedSentence;