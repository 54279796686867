import React from 'react';

const Buttons = (props) => {
  return(
    <section className="buttons mt-3">
      {props.children}
    </section>
  );
}

export default Buttons;