import React from 'react';

// CSS
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './scss/app.scss';

// Components
import Instructions from "./components/Instructions";
import SentenceBuilder from "./components/SentenceBuilder";
import Buttons from "./components/Buttons";
import StepButton from "./components/StepButton";
import FinishedSentence from "./components/FinishedSentence";
import ShareModal from "./components/ShareModal";



const isSentenceItemTwoLines = function (ele) {
  // This class tests if it would overflow if it were one line
  ele.classList.add('test-as-one-line');
  const scrollable = (ele.scrollHeight -2) > ele.clientHeight;
  ele.classList.remove('test-as-one-line');
  return scrollable;
}

const getSentenceClasses = function (ele) {
  // 2 is for border
  const hasScrollableContent = isSentenceItemTwoLines(ele);
  let sentenceClass = '';
  let sentenceItemClass = '';

  if (hasScrollableContent) {
    sentenceClass = ' has-two-lines';
    sentenceItemClass = ' sentence__input--two-lines';
  } else {
    const allSentenceItems = [
      document.getElementById('sentenceVerb1'),
      document.getElementById('sentenceVerb2'),
      document.getElementById('sentenceObject1'),
      document.getElementById('sentenceObject2')
    ];

    // Are any of the other items two lines?
    for (const item of allSentenceItems) {
      // Skip the one we just tested
      if (item.id !== ele.id) {
        if (item.classList.contains('sentence__input--two-lines')) {
          // Tell the container at leaset one has two lines
          sentenceClass = ' has-two-lines';
          break;
        }
      }
    }
  }

  return {
    'sentence': sentenceClass,
    'item': sentenceItemClass
  };
};




class App extends React.Component {
  constructor(props) {
    super(props);
    this.handleStepChange = this.handleStepChange.bind(this);
    this.handleTextareaChange = this.handleTextareaChange.bind(this);
    this.lorem = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut. ";
    this.state = {
      step: 1,
      sentenceVerb1: '',
      sentenceVerb2: '',
      sentenceObject1: '',
      sentenceObject2: '',
      sentenceVerb1Classes: '',
      sentenceVerb2Classes: '',
      sentenceObject1Classes: '',
      sentenceObject2Classes: '',
      sentenceClasses: ''
    }
  }
  

  handleTextareaChange = (event) => {
    const target = event.target;
    const targetId = target.id;
    const value = target.value;
    const sentenceClasses = getSentenceClasses(target, this.props);
    const sentenceItem = target.id + "Classes";
    this.setState({
      [targetId]: value,
      [sentenceItem]: sentenceClasses.item,
      sentenceClasses: sentenceClasses.sentence
    });
  }

  handleStepChange(newStep, label) {   
    this.setState({step: newStep});
  }

  render() {
    return(
        <main className="container">
          <div className="app" step={this.state.step}>
          <Instructions 
            sentenceVerb1={this.state.sentenceVerb1}
            sentenceVerb2={this.state.sentenceVerb2}
            sentenceObject1={this.state.sentenceObject1}
            sentenceObject2={this.state.sentenceObject2}
          />
          <SentenceBuilder 
            onChange={this.handleTextareaChange} 
            sentenceVerb1={this.state.sentenceVerb1}
            sentenceVerb2={this.state.sentenceVerb2}
            sentenceObject1={this.state.sentenceObject1}
            sentenceObject2={this.state.sentenceObject2}
            sentenceVerb1Classes={this.state.sentenceVerb1Classes}
            sentenceVerb2Classes={this.state.sentenceVerb2Classes}
            sentenceObject1Classes={this.state.sentenceObject1Classes}
            sentenceObject2Classes={this.state.sentenceObject2Classes}
            sentenceClasses={this.state.sentenceClasses}
          />
          <Buttons>
            <StepButton
              label={'Start Over'}
              onStepChange={this.handleStepChange}
              step={this.state.step} />
            <StepButton
              label={'Back'}
              onStepChange={this.handleStepChange}
              step={this.state.step} />
            <StepButton
              label={'Next'}
              onStepChange={this.handleStepChange}
              step={this.state.step} />
            <ShareModal />
            
          </Buttons>
          <FinishedSentence 
            sentenceVerb1={this.state.sentenceVerb1}
            sentenceVerb2={this.state.sentenceVerb2}
            sentenceObject1={this.state.sentenceObject1}
            sentenceObject2={this.state.sentenceObject2}
          />
          </div>
        </main>
    );
  }
}

export default App;
