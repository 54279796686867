import React from 'react';

import SentenceInput from "../components/SentenceInput";

import arrow from '../images/arrows-top-sm.svg';


const SentenceBuilder = (props) => {
  
  return(
    
    <section className="overflow-hidden">
        <div className={`sentence step-6-hidden ${props.sentenceClasses}`}>
          <div className="sentence__i sentence__prefix">I</div>
          <SentenceInput 
            placeholder="Verb 1"
            classes={`sentence__verb-1 ${props.sentenceVerb1Classes}`}
            id="sentenceVerb1"
            tabIndex="3"
            defaultValue={props.sentenceVerb1}
            onChange={(event) => props.onChange(event)} 
          />
          <SentenceInput 
            placeholder="Object 1"
            classes={`sentence__object sentence__object-1 ${props.sentenceObject1Classes}`}
            id="sentenceObject1"
            tabIndex="1"
            defaultValue={props.sentenceObject1}
            onChange={(event) => props.onChange(event)} 
          />
          <div className="item3 sentence__arrow  | step-2-flex step-3-flex step-2-opacity step-3-opacity sentence__input--two-linesX">
            <img src={arrow} className="sentence__arrow-item" alt="Instructional arrow" />
          </div>  
          <div className="sentence__and sentence__prefix | step-3-up-opacity">and</div>
          <SentenceInput 
            placeholder="Verb 2"
            classes={`sentence__verb-2 | step-3-up-opacity ${props.sentenceVerb2Classes}`}
            id="sentenceVerb2"
            tabIndex="4"
            defaultValue={props.sentenceVerb2}
            onChange={(event) => props.onChange(event)} 
          />
          <SentenceInput 
            placeholder="Object 2"
            classes={`sentence__object sentence__object-2 ${props.sentenceObject2Classes}`}
            id="sentenceObject2"
            tabIndex="2"
            defaultValue={props.sentenceObject2}
            onChange={(event) => props.onChange(event)} 
          />
        </div>
      </section>
  );
}


export default SentenceBuilder;