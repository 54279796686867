import React, { useState } from "react";
import * as htmlToImage from "html-to-image";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import ShareButtons from "../components/ShareButtons";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";

const ShareModal = (props) => {
  // const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);

    let node = document.getElementById("finished-sentence__banner");
    htmlToImage
      .toPng(node)
      .then(function (dataUrl) {
        let target = document.getElementById("share-content");
        var img = new Image();
        img.src = dataUrl;
        target.appendChild(img);
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
  }

  return (
    <>
      <Button
        className="me-2 btn-lg bg-dark step-6 border-dark"
        onClick={() => handleShow(true)}
      >
        Share
      </Button>
      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title className="h6 text-center w-100">
            Control + click to download or copy image
            <ShareButtons />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center">
          <div id="share-content" className="share-content"></div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ShareModal;
