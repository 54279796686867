import React from 'react';

const SentenceInput = props => {
  return (
    <textarea 
      onChange={ props.onChange }
      id={props.id}
      defaultValue={props.defaultValue}
      className={`sentence__input ${props.classes}`}
      placeholder={props.placeholder}
      tabIndex={props.tabIndex}
    />
  );
};



export default SentenceInput;