import React from 'react';
import Button from 'react-bootstrap/Button';

class StepButton extends React.Component {
  
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    let newStep;
    const step = this.props.step;
    const label = this.props.label;

    if (label === 'Next') {
      newStep = step + 1;
    } else if (label === 'Back') {
      newStep = step - 1;
    } else if (label === 'Start Over') {
      newStep = 1;
    }
    this.props.onStepChange(newStep);
  }

  render() {    
    let disabled = false;
    let classes = 'me-3';
    const label = this.props.label;

    if (label === 'Next') {
      classes = classes + ' step-6-hidden';
    } else if (label === 'Back') {
      // disabled = this.props.step < 2;
      classes = classes + ' step-1-hidden';
    } else if (label === 'Start Over') {
      classes = classes + ' step-6';
    }
    
    return (
      <Button onClick={this.handleClick} disabled={disabled} className={classes}>
        {label}
      </Button>
    );
  }
}

export default StepButton;