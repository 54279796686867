import React from 'react';

const Instructions = (props) => {
  return(
    <section>
      <ol className="instructions py-6">
        <li className="step-1-vh">Name two objects</li>
        <li className="step-2-vh">
          What’s the <strong className="normal">most normal</strong> thing done to “{props.sentenceObject1}” 
          &nbsp;...but <strong className="never">never</strong> done to “<span className="output-object-2">{props.sentenceObject2}</span>”?
        </li>
        <li className="step-3-vh">
          What’s the <strong className="normal">most normal</strong> thing done to 
          “<span className="output-object-2">{props.sentenceObject2}</span>” 
          ...but <strong className="never">never</strong> done to 
          “<span className="output-object-1">{props.sentenceObject1}</span>”?</li>
        <li className="step-4-vh">Make it past tense. 
          <span className="suggestion d-none">
            Prepesitions or adjectives? 
            <span className="small">
              i.e. “<em>My yummy</em> <span className="output-object-1">{props.sentenceObject1}</span>”
            </span>
          </span>
        </li>
        <li className="step-5-vh">
          Review and cleanup.  
        </li>
        <li className="step-6-vh">Customize and share</li>
      </ol>
    </section>
  );
}

export default Instructions;