import React from "react";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { FacebookIcon, TwitterIcon } from "react-share";


import instagram from '../images/instagram-icon.svg';

const ShareButtons = () => {
  const url = "https://onetime.lol";
  const quote = "One Time... and it worked.";
  const hashtag = "#onetime";
  const className = "m-1";

  return (
    <div className="d-inline-block mx-2">
      <FacebookShareButton
        url={url}
        quote={quote}
        hashtag={hashtag}
        description={quote}
        className={className}
      >
        <FacebookIcon size={32} round /> 
      </FacebookShareButton>
 
 
      <TwitterShareButton
        title={quote}
        url={url}
        hashtags={[{hashtag}]}
        className={className}
      >
        <TwitterIcon size={32} round />
      </TwitterShareButton>

      <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="mx-2" alt="Instagram icon"><img src={instagram} width="32" /></a>

      <meta property="og:image" content="http://static01.nyt.com/images/2015/02/19/arts/international/19iht-btnumbers19A/19iht-btnumbers19A-facebookJumbo-v2.jpg" />

    </div>
  );
}


export default ShareButtons;