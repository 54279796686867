import React from 'react';

class ForegroundColorPicker extends React.Component {

  constructor(props) {
    super(props);
    this.handleForegroundChange = this.handleForegroundChange.bind(this);
  }

  handleForegroundChange() {
    this.props.onColorChange();
  }

  render() {
    return(
      <div className={`color-picker__foreground px-3`} onClick={this.handleForegroundChange}>T </div> 

      
    )
  }
}

export default ForegroundColorPicker;